import React from "react";
import Link from "next/link";
import { DOCS_LINK } from "lib/constants";

const ErrorPage = ({ title, text, statusCode }) => {
  return (
    <div className="grid h-screen w-screen items-center justify-center">
      <div className="max-w-8xl prose prose-xl prose-blue p-8">
        {statusCode}
        <h1 className="text-3xl font-bold leading-8 tracking-tight text-gray-900">{title}</h1>

        <p>{text}</p>
        <p>
          <Link href="/">
            <a>Home</a>
          </Link>
        </p>
        <p>
          <a href={DOCS_LINK} target="__blank">
            Docs
          </a>
        </p>
      </div>
    </div>
  );
};
export default ErrorPage;
