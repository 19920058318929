import React from "react";
import ErrorPage from "components/templates/ErrorPage";

export default function NotFound() {
  // Opinionated: we deliberately do not record an exception in Sentry for 404
  return (
    <ErrorPage
      title={"Page Not Found"}
      statusCode={404}
      text={"The page can't be found. We might have moved it or the link might be broken. Have a look below or let us know on chat."}
    />
  );
}
